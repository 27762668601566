
const LOADING_START_DELAY = 10; //10ms

import Promise from "bluebird";

export default {
  data() {
    return {
      
    }
  },
  computed : {
    isLoading(){
      return this.$store.state.erns.loading.isLoading;
    },
    loadingData(){
      return this.$store.state.erns.loading;
    }
  },
  methods: {
    async loading(show) {
      const _vm = this;
      _vm.loadingSpinner(show);
    },
    
    async loadingSpinner(show) {
      let loading = this.$store.state.erns.loading;
      loading.isLoading = show;
      loading.mode = 'spinner';
      if(loading.isLoading){
        loading.spinner.loader = this.$loading.show({
          // Optional parameters
          container: null,
          canCancel: false
        });
      }else{
        const loader = loading.spinner.loader;
        if(loader){
          loader.hide();
        }        
      }
      
      this.$store.dispatch('erns/setLoading', loading);
      await Promise.delay(LOADING_START_DELAY);
    },

    async loadingProgress(show, value, max) {
      let loading = this.$store.state.erns.loading;
      loading.isLoading = show;
      loading.mode = 'progress';
      loading.progress = {
        ...loading.progress,
        value : value || 0,
        max : max || 100
      };
      this.$store.dispatch('erns/setLoading', loading);      
      await Promise.delay(LOADING_START_DELAY);
    }
  }
}
