export default {
  data() {
    return {

    }
  },
  filters: {
    
  },
  methods: {
    sonaxUserUiInit_documentReady(){
      const _vm = this;
      _vm.sonaxUserUiInit_header();
      _vm.sonaxUserUiInit_nav();
      _vm.sonaxUserUiInit_backToTop();
      _vm.sonaxUserUiInit_sticky();
    },

    /*header*/
    sonaxUserUiInit_header(){
      var rollHeader = 100;
      $(window).scroll(function () {
        var scroll = window.pageYOffset || document.documentElement.scrollTop;
        if (scroll >= rollHeader) {
          $('.header').addClass('scroll');
        } else {
          $('.header').removeClass('scroll');
        }
      });
    },

    /*nav*/
    sonaxUserUiInit_nav(){
      var rollHeader = 100;
      
      $(window).scroll(function () {
        var scroll = window.pageYOffset || document.documentElement.scrollTop;
        if (scroll >= rollHeader) {
          $('.nav').addClass('scroll');
        } else {
          $('.nav').removeClass('scroll');
        }
      });
    },

    /*back-to-top */
    sonaxUserUiInit_backToTop(){
      var btn = $('#back-to-top');

      $(window).scroll(function () {
        if ($(window).scrollTop() > 300) {
          btn.addClass('show');
        } else {
          btn.removeClass('show');
        }
      });

      btn.on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({
          scrollTop: 0
        }, '400');
      });
    },

    /*sticky*/
    sonaxUserUiInit_sticky(){
      $(window).scroll(function () {
        var navOffset = $('.tabs-fixed').offset();
        
        if(navOffset){
          if ($(document).scrollTop() > navOffset.top - 80) {
            $('.tabs-fixed').addClass('active');
          } else {
            $('.tabs-fixed').removeClass('active');
          }
        }
      });
    },

    /* custom-select */
    sonaxUserUiInit_customSelect(){
      $(".custom-select").each(function() {
        var classes = $(this).attr("class"),
            id      = $(this).attr("id"),
            name    = $(this).attr("name");
        var template =  '<div class="' + classes + '">';
            template += '<span class="custom-select-trigger">' + $(this).attr("placeholder") + '</span>';
            template += '<div class="custom-options">';
            $(this).find("option").each(function() {
              template += '<span class="custom-option ' + $(this).attr("class") + '" data-value="' + $(this).attr("value") + '">' + $(this).html() + '</span>';
            });
        template += '</div></div>';
        
        $(this).wrap('<div class="custom-select-wrapper"></div>');
        $(this).hide();
        $(this).after(template);
      });

      $(".custom-option:first-of-type").hover(function() {
        $(this).parents(".custom-options").addClass("option-hover");
      }, function() {
        $(this).parents(".custom-options").removeClass("option-hover");
      });

      $(".custom-select-trigger").on("click", function() {
        $('html').one('click',function() {
          $(".custom-select").removeClass("opened");
        });
          $(".custom-select").not($(this).parents(".custom-select")).removeClass("opened");
        $(this).parents(".custom-select").toggleClass("opened");
        event.stopPropagation();
      });
      
      $(".custom-option").on("click", function(e) {
        //$(this).parents(".custom-select-wrapper").find("select").val($(this).data("value"));
        var selObj = $(this).parents(".custom-select-wrapper").find("select");
        var oldVal = selObj.val();
        var newVal = $(this).data("value");

        selObj.val(newVal);
        if(!oldVal || oldVal != newVal){
          selObj.trigger("change");        
        }

        $(this).parents(".custom-options").find(".custom-option").removeClass("selection");
        $(this).addClass("selection");
        $(this).parents(".custom-select").removeClass("opened");
        $(this).parents(".custom-select").find(".custom-select-trigger").
        text($(this).text());
      });
    },

    sonaxUserUiInit_accordion(){
      var Accordion = function (el, multiple) {
        this.el = el || {};
        this.multiple = multiple || false;

        // Variables privadas
        var links = this.el.find('.link');
        // Evento
        links.on('click', {
          el: this.el,
          multiple: this.multiple
        }, this.dropdown)
      }

      Accordion.prototype.dropdown = function (e) {
        var $el = e.data.el;
        var $this = $(this), $next = $this.next();

        $next.slideToggle();
        $this.parent().toggleClass('open');

        if (!e.data.multiple) {
          $el.find('.submenu').not($next).slideUp().parent().removeClass('open');
        }
      }

      var accordion = new Accordion($('.accordion'), true);
    }

  }
}
