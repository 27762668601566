import useJwt from '@/auth/jwt/useJwt';
import jwtDefaultConfig from '@core/auth/jwt/jwtDefaultConfig';
import sonaxConfig from '@/config';
import {setPushToken} from '@/api/sonax-user/push/push';
import {getLatestVersion, getUpperVersion } from '@/api/sonax-user/comm/ver';

//iOS 배포 기본값
const DEF_IOS_VERSION_INFO = {
  "bundleId" : "com.sonax.checkin.user"
  , "appId" : "com.sonax.checkin.user"
  , "versionName" : "1.1"
  , "versionCode" : 4
};

//Android 배포 기본값
const DEF_ANDROID_VERSION_INFO = {
  "appId" : "com.sonax.checkin.user"
  , "versionName" : "1.16"
  , "versionCode" : 17
};

const VUE_APP_STORE_ANDROID = "https://play.google.com/store/apps/details?id=com.sonax.checkin.user";
//const VUE_APP_STORE_IOS = "itms-appss://apps.apple.com/app/id1570209067";
const VUE_APP_STORE_IOS = "https://apps.apple.com/app/%EC%86%8C%EB%82%99%EC%8A%A4-%EC%B2%B4%ED%81%AC%EC%9D%B8-%EC%86%8C%EB%82%99%EC%8A%A4-%EC%B9%B4%EC%BC%80%EC%96%B4-%EC%98%88%EC%95%BD/id1570209067";

export default {
  data() {
    return {
      //앱버전
      isLowVersion : false,
      hasRequireUpperVerison : false,
      appVersionInfo : {
        ...DEF_IOS_VERSION_INFO
      },
      lastAppVersionInfo : {
        ...DEF_IOS_VERSION_INFO
      }
    }
  },
  filters: {
    
  },
  computed: {
    isAppDevice(){
      return this.$store.state.erns.deviceType == "APP";
    },
    deviceType(){
      return this.$store.state.erns.deviceType;
    },
    deviceTypeNative(){
      return this.$store.state.erns.deviceTypeNative;
    },
    isAppAndroid(){
      return this.$store.state.erns.deviceTypeNative == "ANDROID";
    },
    isAppIos(){
      return this.$store.state.erns.deviceTypeNative == "IOS";
    },
    isMain(){
      return this.$route.name == "sonax-user-main"
    },
  },
  methods: {  
    ermOpenWindow(url){
      const _vm = this;      
      
      if(_vm.isAppDevice && window.sonax_app && window.sonax_app.openBrowser){        
        window.sonax_app.openBrowser(url);
        return;
      }
      
      window.open(url, '_blank');
    },

    ermStackPage(routerData, modalCloseCallback, message){
      const _vm = this;      
      //console.debug("$this.$router");
      //console.debug(_vm.$router);

      if(_vm.isAppDevice && window.sonax_app && window.sonax_app.stackPage){
        window.modalCloseCallback = modalCloseCallback;

        const esc = encodeURIComponent;
        const params = routerData.query;
        let query = "";
        if(params){
          query = "?"+Object.keys(params).map(k => esc(k) + '=' + esc(params[k])).join('&');        
        }
        const url = `${routerData.path}${query}`;
        //console.debug(url);
        window.sonax_app.stackPage(url, message);
        return;
      }
      
      _vm.$router.push(routerData).catch(err => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          console.error(err);
        }
      });
    },

    ermPushPage(routerData){
      const _vm = this;      
      // //console.debug("$this.$router");
      // //console.debug(_vm.$router);
      // if(_vm.isAppDevice && window.sonax_app && window.sonax_app.stackPage){
      //   window.sonax_app.stackPage(routerData.path);
      //   return;
    // }
      
      _vm.$router.push(routerData).catch(err => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (!(
          (err.name == 'NavigationDuplicated' &&
            err.message.includes('Avoided redundant navigation to current location'))
          || err.message.includes('Redirected when going from')
        )) {
          console.error(err);
        }
      });
    },

    ermReplacePage(routerData){
      const _vm = this;      
      // //console.debug("$this.$router");
      // //console.debug(_vm.$router);
      // if(_vm.isAppDevice && window.sonax_app && window.sonax_app.stackPage){
      //   window.sonax_app.stackPage(routerData.path);
      //   return;
      // }
      
      _vm.$router.replace(routerData).catch(err => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (!(
          (err.name == 'NavigationDuplicated' &&
            err.message.includes('Avoided redundant navigation to current location'))
          || err.message.includes('Redirected when going from')
        )) {
          console.error(err);
        }
      });
    },

    //환경설정 오픈
    ermSettingPage(){
      const _vm = this;
      //console.debug("$this.$router");
      //console.debug(_vm.$router);
      if(_vm.isAppDevice && window.sonax_app && window.sonax_app.showSetting){
        const data = {
          tokenType : jwtDefaultConfig.tokenType,
          accessToken : useJwt.getToken()
        }
        
        window.sonax_app.showSetting(JSON.stringify(data));
        return;
      }
      
      _vm.ermStackPage({path:'/sonax/mypage/setting'}, (message)=>{
        if(_vm.isAppDevice && window.sonax_app){
          _vm.fetchData();
          return;
        }
      }, "");
    },

    //Push token 가져오기
    ermGetPushToken(){
      const _vm = this;

      if(_vm.isAppDevice && window.sonax_app && window.sonax_app.getPushToken){
        const pushToken = window.sonax_app.getPushToken();
        console.debug(`pushToken:${pushToken}`);
        return pushToken;
      }

      return "";
    },

    //Push token 전송
    async ernSendPushToken(){
      const _vm = this;

      if(!(_vm.isAppDevice && window.sonax_app && window.sonax_app.getPushToken)){
        return;
      }
      
      try{
        const pushTokenDataJson = localStorage.getItem(sonaxConfig.storagePushTokenKey);
        let pushTokenInfo = _vm.$store.state.erns.pushTokenInfo;
        if(pushTokenDataJson){
          const sPushToken = JSON.parse(pushTokenDataJson);
          pushTokenInfo = {...sPushToken};        
        }

        const curPushToken = _vm.ermGetPushToken();
        pushTokenInfo.token = curPushToken;
        _vm.$store.dispatch('erns/setPushTokenInfo', pushTokenInfo);
        
        const userInfo = _vm.$store.state.erns.userInfo;
        pushTokenInfo = _vm.$store.state.erns.pushTokenInfo;
        console.debug('pushTokenInfo:');
        console.debug(JSON.stringify(pushTokenInfo));

        if(userInfo 
          && userInfo.userSeq && userInfo.userSeq > 0
          && pushTokenInfo
          && pushTokenInfo.token && pushTokenInfo.token != ""
          //&& pushTokenInfo.transToken != pushTokenInfo.token
        ){
          const sendToken = pushTokenInfo.token;
          console.debug("send Token : "+ sendToken);

          //send token
          const pushParam = {
            userSeq : userInfo.userSeq,
            appId : sendToken,
            devType : _vm.$store.state.erns.deviceTypeNative
          }
          const res = await setPushToken(pushParam);
          if(res.result == "S"){
            pushTokenInfo.transToken = sendToken;
            pushTokenInfo.transDate = (new Date()).getTime();

            _vm.$store.dispatch('erns/setPushTokenInfo', pushTokenInfo);
          }
        }else{
          console.debug("don't send Token : "+ pushTokenInfo.token);
        }
      }catch(err){
        console.debug(err);
      }
    },

    hasHistory () { return window.history.length > 2 },
    ermHistoryBack(){
      const _vm = this; 

      if(_vm.isAppDevice && window.sonax_app && window.sonax_app.historyBack){        
        window.sonax_app.historyBack();
        return;
      }

      if(_vm.hasHistory()){
        _vm.$router.go(-1);
      }else{
        _vm.ermReplacePage({name:'sonax-user-main'});
      }
    },

    //클립보드 복사
    async edmSetClipboard(data, message){
      const _vm = this;

      try{
        await _vm.$copyText(data);

      }catch(e){
        console.log(e);
        return;
      }
      
      if(_vm.isAppDevice && window.sonax_app && window.sonax_app.showToast){        
        var msg = message || '클립보드에 복사 되었습니다.';
        window.sonax_app.showToast(msg);
        return;
      }
    },

    //전화 다이얼
    async edmCallDial(tel){
      const _vm = this;

      if(_vm.isAppDevice && window.sonax_app && window.sonax_app.callDial){        
        window.sonax_app.callDial(tel);
        return;
      }
    },

    //애플로그인
    edmAppleLogin(){
      const _vm = this;

      if(_vm.isAppDevice && window.sonax_app && window.sonax_app.appleLogin){        
        window.sonax_app.appleLogin();
        return true;
      }

      return false;
    },

    //App용 javascript interface 객체 가져오기
    getAppIF(){
      return window.sonax_app;
    },

    //App Interface 함수 유효성 검증
    hasAppFunction(functionName){
      const _vm = this;
      const appIF = _vm.getAppIF();
      if(_vm.isAppDevice 
        && appIF 
        && appIF[functionName]){
        return true;
      }
      return false;
    },

    //App Version 가져오기
    ermGetAppVersionInfo(){
      const _vm = this;

      const defaultInfo = {...DEF_IOS_VERSION_INFO};
      if(!_vm.isAppDevice){
        return defaultInfo; 
      }
      
      if(_vm.hasAppFunction('getVersionInfo')){
        const appVersionInfo =_vm.getAppIF().getVersionInfo();
        console.debug(`AppVersionInfo:${appVersionInfo}`);
        try{
          return JSON.parse(appVersionInfo);
        }catch(e){
          console.error(e);
        }
      }
      
      const verJson = JSON.stringify(_vm.isAppIos ? DEF_ANDROID_VERSION_INFO : DEF_IOS_VERSION_INFO);
      return JSON.parse(verJson);
    },

    //최신버전 가져오기
    async appVersionCheck() {
      const _vm = this;

      const versionInfo = _vm.ermGetAppVersionInfo();

      const params = {
        pfCode : "0",
        verCode : versionInfo.versionCode || "0",
        appId : versionInfo.appId
      };
      if(_vm.isAppAndroid){
        params["pfCode"] = "1"
      }
      if(_vm.isAppIos){
        params["pfCode"] = "2"
      }

      _vm.isLowVersion = false;
      _vm.hasRequireUpperVerison = false;
      const verData = await getUpperVersion(params);
      const upperVersionList = verData.objReturnData;

      if(!upperVersionList){
        return;
      }

      const upperList = upperVersionList.filter(item => item.pfCode == params["pfCode"]);

      //상위 버전이 있는지 확인
      const checkUpperVer = upperList.find(item => parseInt("" + versionInfo.versionCode) < item.verCode);
      if(checkUpperVer){
        _vm.isLowVersion = true;
        _vm.lastAppVersionInfo = checkUpperVer;
      }

      console.debug(upperList);

      //필수 상위 버전이 있는지 확인
      const checkReqUpperVer = upperList.find(item => {
        return item.reqYn == "Y" && parseInt("" + versionInfo.versionCode) < item.verCode;
      });
      if(checkReqUpperVer){
        _vm.hasRequireUpperVerison = true;
      }
    },

    //최신버전 다운로드 
    async onClickGetNewApp(isForce) {
      const _vm = this;

      let link = VUE_APP_STORE_ANDROID;

      console.debug(`onClickGetNewApp : ${_vm.isLowVersion}`);

      if(!_vm.isLowVersion){
        return;
      }

      if(_vm.isAppAndroid) {
        link = VUE_APP_STORE_ANDROID;
      }
      
      if(_vm.isAppIos) {
        link = VUE_APP_STORE_IOS;
      }

      if(isForce === true){
        _vm.loading(false);
        await _vm.$swal({
          icon: "warning",
          //title : "",
          html: "앱이 최신 버전이 아닙니다.<br/> 최신버전으로 업데이트 하십시오.",         
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-primary btn-lg',
            cancelButton : 'btn btn-grey-outline btn-lg',
          },
          confirmButtonText: "업데이트",          
        });

        document.location.href = link;
        return;

      }else{
        const moveToAppStore =  await _vm.$swal({
          icon: "info",
          //title : "",
          html: "앱이 최신 버전이 아닙니다.<br/>업데이트 하시겠습니까?",         
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-primary btn-lg',
            cancelButton : 'btn btn-grey-outline btn-lg',
          },
          showCancelButton : true,          
          confirmButtonText: "확인",
          cancelButtonText: "취소",
          closeOnConfirm : false,
			    closeOnCancel : true
        });

        console.debug(moveToAppStore);
        
        if(moveToAppStore.isConfirmed) {
          document.location.href = link;
        }
      }      
    },

    //IOS javascript interface 등록
    edmRegisterIsoJavascriptInterface(){
      const _vm = this;
      
      window.sonax_app ={
        // historyBack : function(message){
        //   window.webkit.messageHandlers.back.postMessage(true);
        // },
        openBrowser : function(link){
          console.debug(link);
          window.webkit.messageHandlers.outLink.postMessage(link);
        },
        showToast : function(message){
          console.debug(message);
          window.webkit.messageHandlers.showToast.postMessage(message);
        },                
        getPushToken : function(){
          //const ret = window.webkit.messageHandlers.getPushToken.postMessage();
          const iosPushToken = _vm.$cookie.get("sonaxService.app.device.pushToken") || "";
          console.debug(`getPushToken:${iosPushToken}`);
          return iosPushToken;
        },
        appleLogin : function(){
          console.info("appleLogin");
          window.webkit.messageHandlers.appleLogin.postMessage("test");
        },
        getVersionInfo : function(){
          //window.webkit.messageHandlers.versionInfo.postMessage("");
          const iosVersionInfo = _vm.$cookie.get("sonaxService.app.device.version") || "{}";
          console.debug(`getVersionInfo:${iosVersionInfo}`);

          if(iosVersionInfo && iosVersionInfo != "{}"){
            //가져올 수 있으면 가져온 값 리턴
            return iosVersionInfo;
          }

          //가져올 수 없으면 기본값
          return JSON.stringify(DEF_IOS_VERSION_INFO);
        }
      }
    }
  }
}
