import useJwt from '@/auth/jwt/useJwt'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem(useJwt.jwtConfig.storageUserDataKeyName) 
    && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem(useJwt.jwtConfig.storageUserDataKeyName))

// 로그인시 이동할 기본페이지
export const getHomeRouteForLoggedInUser = () => {
  //return { name: 'auth-login' }
  return "/"; //home
}
