<template>
  <footer>
    <div class="footer-area">
      <div class="container">
        <div class="footer-wrap">
          <div class="footer-l">
            <div class="footer-info-area">
              <div class="footer-title">(주) 알레스 </div>
              <div class="footer-info">
                <li>주소 : {{eumConstAdress}}</li>
                <li>고객센터 : {{eumConstCustomerCenter}}<br class="pc-none">
                  (평일 09:00~18:00, 점심시간 12:00~13:00, 주말·공휴일 휴무)</li>
                <li>사업자등록번호 : {{eumConstBusinessNum}}<span class="footer-line">대표 : 이준형</span> 
                  <span class="footer-num">통신판매업 신고번호 2022 - 성남분당C - 0457호 
                  <a href="javascript:void(0);" @click="onClickBizCommView">사업자정보확인</a></span>                  
                </li>
              </div>
              <div class="footer-copy">Copyright ⓒ 2020 ALLES Co,. LTD. All rights reserved.</div>
            </div>
          </div>
          <div class="footer-r">
            <li><a href="javascript:void(0);" @click="termsModal01.show = true;">이용약관</a></li>
            <li><a href="javascript:void(0);" @click="termsModal02.show = true;">개인정보처리방침</a></li>
            <li><a href="javascript:void(0);" @click="termsModal03.show = true;">위치기반서비스약관</a></li>
          </div>
        </div>
      </div>
    </div>

    <!--agree-modal-->
    <transition name="fade-in">
    <div v-show="termsModal01.show" class="modal" id="for-agree-modal">
      <div class="layer">
        <div class="layer-content">
          <div class="modal-inner modal-md">
            <div @click="termsModal01.show = false;" class="modal-close"></div>
            <div class="modal-header b-b-0">
              <div class="header-title">{{termsModal01.name}}</div>
            </div>
            <div class="modal-content scroll-content agree-content" 
              v-html="termsModal01.contents"
            ></div>
          </div>
        </div>
      </div>
    </div>
    </transition>

    <!--privacy-modal-->
    <transition name="fade-in">
    <div v-show="termsModal02.show" class="modal" id="for-privacy-modal">
      <div class="layer">
        <div class="layer-content">
          <div class="modal-inner modal-md">
            <div @click="termsModal02.show = false;" class="modal-close"></div>
            <div class="modal-header b-b-0">
              <div class="header-title">{{termsModal02.name}}</div>
            </div>
            <div class="modal-content scroll-content agree-content"
              v-html="termsModal02.contents"
            ></div>
          </div>
        </div>
      </div>
    </div>
    </transition>

    <!--location-modal-->
    <transition name="fade-in">
    <div v-show="termsModal03.show" class="modal" id="for-location-modal">
      <div class="layer">
        <div class="layer-content">
          <div class="modal-inner modal-md">
            <div @click="termsModal03.show = false;" class="modal-close"></div>
            <div class="modal-header b-b-0">
              <div class="header-title">{{termsModal03.name}}</div>
            </div>
            <div class="modal-content scroll-content agree-content"
              v-html="termsModal03.contents"
            ></div>
          </div>
        </div>
      </div>
    </div>
    </transition>

  </footer>
</template>

<script>
import ernsUtils from '@/components/mixins/ernsUtils';
import {getCodes} from '@/api/sonax-user/comm/code';
import {getTermList} from '@/api/sonax-user/comm/terms';

export default {
  name: 'AppFooter',
  mixins: [ernsUtils],
  computed: {    
  },
  mounted(){
    const _vm = this;

    _vm.fetchData();
    _vm.loadCodes();
  },
  data(){
    return {
      termsModal01 : {
        show : false,
        termId : 'TERMS_01', //이용약관
        name : '', contents : '',
      },
      termsModal02 : {
        show : false,
        termId : 'TERMS_02', //개인정보처리방침
        name : '', contents : '',
      },
      termsModal03 : {
        show : false,
        termId : 'TERMS_03', //위치기반서비스약관
        name : '', contents : '',
      },
      codes : {
        TERMS_CODE : []
      }
    }
  },
  methods : {
    fetchData(){
      const _vm = this;
    },

    //통신판매업 신고번호
    onClickBizCommView(){
      const _vm = this;
      
      _vm.ermOpenWindow('http://www.ftc.go.kr/bizCommPop.do?wrkr_no=2158666670');
    },

    async loadCodes(){
      const _vm = this;

      try{
        //_vm.loading(true);

        { //약관코드 가져오기
          const codes = [
            _vm.termsModal01.termId,
            _vm.termsModal02.termId,
            _vm.termsModal03.termId
          ];          
          const rawTermsCdList = await getCodes({
            codeMst:'TERMS_CODE', codes:codes, pageSize:-1
          });
          _vm.codes.TERMS_CODE = rawTermsCdList.objReturnData;
        }
        
        { //약관내용 로딩
          const termsIds = _vm.codes.TERMS_CODE.map(cd=>cd.code);
          const params = {
            termsIds : termsIds,
            termsType: 'TERMS',
            useYn : 'Y'
          };

          const res = await getTermList(params);

          const termsModels = [
            "termsModal01", "termsModal02", "termsModal03"
          ];
          
          //약관 정보 설정 
          for(const key of termsModels){
            const tInfo = _vm[key];
            //console.debug(tInfo);            
            
            //약관 이름
            const termCd = _vm.codes.TERMS_CODE.find((cd)=>tInfo.termId == cd.code);
            if(termCd){              
              tInfo.name = termCd.codeNm;
            }
            
            //약관 내용
            const rawTerm = res.objReturnData.find((rt)=>{
              return tInfo.termId == rt.termsId;
            });
            
            if(rawTerm){
              tInfo.contents = rawTerm.content || '';
              tInfo.contents = tInfo.contents.replace(/\n/g, "<br/>");
              tInfo.required = 'Y' == rawTerm.requiredYn;
            }
          }
        }
      
      }catch(err){
        console.error(err);
      }finally{
        _vm.loading(false);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.app-footer {
  
}
</style>

<style lang="scss">
</style>
