export default {
  // pages
  loginPage : '/sonax-user/sonax/login',

  // Endpoints
  loginEndpoint: '/api/user/login/login.api',
  refreshEndpoint: '/api/login/refreshToken.api',

  registerEndpoint: '/sonax-user/sonax/join',  
  logoutEndpoint: '/api/login/logout.api',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'sonax.user.accessToken',
  storageRefreshTokenKeyName: 'sonax.user.refreshToken',

  // UserData
  storageUserDataKeyName: 'sonax.user.userData',
  storageJoinUserDataKeyName: 'sonax.user.joinData',
}
