<template>
  <div id="app" class="wrap" 
    :class="{
      'app': isMain && isAppDevice,      
      ...wrapClass,
      ...wrapAppClass, 
    }">    
    <router-view />
    <!-- <scroll-to-top v-if="enableScrollToTop" />     -->
    <!-- isMain:{{isMain}} / deviceType:{{deviceType}}
    <br/>meta :{{$route.meta}} -->
    <!-- Back to top -->
    <div id="back-to-top"></div>
  </div>  
</template>

<script>
//import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import ernsUtils from '@/components/mixins/ernsUtils';
import uiScript from '@/components/mixins/uiScript';
import {getUserData} from '@/auth/utils';
import sonaxConfig from '@/config';

export default {  
  mixins: [ernsUtils, uiScript],
  components: {
    //ScrollToTop,
  },
  computed: {
    isLoading(){
      return this.$store.state.erns.loading.isLoading;
    },
    wrapClass(){
      const _vm = this;
      const wrapClass = _vm.$route.meta.wrapClass;
      const ret = {};
      if(!wrapClass){
        return ret;
      }

      for(const cn of wrapClass){
        ret[cn] = true;
      }

      if(_vm.isAppDevice){
        ret['m-nav-none'] = true;
      }
      
      return ret;
    },
    wrapAppClass(){
      const _vm = this;
      if(!_vm.isAppDevice){
        return {};
      }

      const wrapAppClass = _vm.$route.meta.wrapAppClass;
      const ret = {};

      if(!wrapAppClass){
        return ret;
      }

      for(const cn of wrapAppClass){
        ret[cn] = true;
      }

      return ret;
    }
  },
  beforeCreate() {
    
  },
  setup() {
   
  }, 
  mounted(){
    const _vm = this;  
    
    _vm.eumLoadKakaoLib();
    
    //loading
    _vm.loading(false);

    //console.debug(_vm.$route);

    { //Set systemServiceCurrentVersion
      const sscv = window.systemServiceCurrentVersion;
      _vm.$store.dispatch('erns/changeOption', {
        key : "systemServiceCurrentVersion", value : sscv
      }); 
    }

    { //load storage data
      const userInfo = getUserData();
      _vm.$store.dispatch('erns/setUserInfo', userInfo);

      const reservationDataJson = localStorage.getItem(sonaxConfig.storageReservationDataKey);
      if(reservationDataJson){
        const reservationData = JSON.parse(reservationDataJson);
        _vm.$store.dispatch('erns/setReservationData', reservationData);
      }
    }
    
    // Set device Type 
    {
      const deviceType = _vm.$cookie.get("sonaxService.app.device") || "WEB";
      console.debug(`deviceType :${deviceType}`);
      _vm.eumSetDeviceType(deviceType || "WEB");    

      const deviceTypeNative = _vm.$cookie.get("sonaxService.app.device.type") || "pc";
      console.debug(`deviceTypeNative :${deviceTypeNative}`);
      _vm.eumSetDeviceTypeNative(deviceTypeNative || "PC");

      if(_vm.isAppIos){      
        console.info(`I am iOS :${deviceTypeNative}`);  
        _vm.edmRegisterIsoJavascriptInterface();
      }
    }

    { //pushToken
      //_vm.ernSendPushToken();
    }
    
    _vm.sonaxUserUiInit_documentReady();

    //Native Modal Activity 종료시 호출
    window.modalActivityClose = function(message){
      if(window.modalCloseCallback && typeof(window.modalCloseCallback) == 'function' ) {
        window.modalCloseCallback(message);
      }
    };

  },
  data() {
    return {
      mainOverlay:{        
        variant: 'dark',
        opacity: 0.10,
        blur: '3px',
        zIndex : 2000,
        rounded : 'lg'
      }
    };
  },  
}
</script>
<style lang="scss">
//@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>