import request from '@/utils/request'

const getLatestVersion = async function(params){
    return request({
        url: '/api/cmm/version/getLatestVersion.api',
        method: 'post',
        data: params
    });
};

const getUpperVersion = async function(params){
    return request({
        url: '/api/cmm/version/getUpperVersion.api',
        method: 'post',
        data: params
    });
};



export {
    getLatestVersion,
    getUpperVersion,
}