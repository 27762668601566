//Layout
import BasicLayout from '@/layouts/BasicLayout';

export default [
  {
    path: '/sonax',
    component: BasicLayout,
    children: [
      {
        path: 'cm/main',
        name: 'sonax-user-cmMain',
        component: () => import('@/views/sonax-user/CmMain.vue'),
        meta: {
          props: true,
          mainNavId : 4,
          wrapClass : ['m-sub'],
          pageTitle: '고객센터',
          breadcrumb: [
            {
              text: '홈',
            },
            {
              text: '고객센터',
              active: true,
            },
          ],
        },
      }
    ]
  },  
]

