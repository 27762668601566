import request from '@/utils/request'

const getCodeMasters = async function(params){
    return request({
        url: '/api/cmm/code/mst/list.api',
        method: 'post',
        data: params
    });
};

const getCodes = async function(params){
    return request({
        url: '/api/cmm/code/list.api',
        method: 'post',
        data: params
    });
};

export {
    getCodeMasters,
    getCodes,
}