<template>
  <transition name="fade-transform" mode="out-in">
    <!-- <keep-alive :include="cachedViews">
      <router-view :key="key" />
    </keep-alive> -->
    <router-view class="app-main"/>
  </transition>
</template>

<script>

export default {
  name: 'AppMain',
  computed: {
    /*
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    key() {
      return this.$route.path
    }*/
  }
}
</script>

<style lang="scss" scoped>
.app-main {
  
}
</style>

<style lang="scss">
</style>
