import request from '@/utils/request'

const getUncheckNotiCount = async function(params){
    return request({
        url: '/api/cmm/noti/getUncheckNotiCount.api',
        method: 'post',
        data: params,
    });
};


export {
    getUncheckNotiCount,
}