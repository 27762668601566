//Layout
import BasicLayout from '@/layouts/BasicLayout';

export default [
  {
    path: '/sonax',
    component: BasicLayout,
    children: [
      {
        path: 'service/main',
        name: 'sonax-user-serviceMain',
        component: () => import('@/views/sonax-user/ServiceMain.vue'),
        meta: {
          requireLogin : true,
          mainNavId : 2,
          wrapClass : ['m-sub'],
          wrapAppClass : ['app-sub-sm'],
          pageTitle: '서비스 예약',
          breadcrumb: [
            { text: '홈',},
            {
              text: '서비스 예약',
              active: true,
            },
          ],
        },
        props: (route) => { 
          const frSeq = parseInt(route.query.frSeq, 10);
          const frName = route.query.frName;
          const state = parseInt(route.query.state, 10);
          if(Number.isNaN(frSeq)){
            return {frSeq: 0};
          }
          return {frSeq, frName, state}
        },
      },
      {
        path: 'service/reservation/serviceChoise',
        name: 'sonax-user-reservationServiceChoise',
        component: () => import('@/views/sonax-user/service/reservation/ServiceChoice.vue'),
        meta: {
          requireLogin : true,
          mainNavId : 2,
          wrapClass : ['m-sub', 'm-nav-none', 'basic'],
          pageTitle: '서비스 예약',
          breadcrumb: [
            { text: '홈',},
            { text: '서비스 예약',},
            {
              text: '서비스 상품 선택',
              active: true,
            },
          ],
        },
      },
      {
        path: 'service/reservation/storeChoice',
        name: 'sonax-user-reservationStoreChoice',
        component: () => import('@/views/sonax-user/service/reservation/StoreChoice.vue'),
        meta: {
          requireLogin : true,
          mainNavId : 2,
          wrapClass : ['m-sub', 'm-nav-none', 'basic'],
          pageTitle: '서비스 예약',
          breadcrumb: [
            { text: '홈',},
            { text: '서비스 예약',},
            {
              text: '서비스점 선택',
              active: true,
            },
          ],
        },
      },
      {
        path: 'service/reservation/serviceDate',
        name: 'sonax-user-reservationDateChoice',
        component: () => import('@/views/sonax-user/service/reservation/ServiceDate.vue'),
        meta: {
          requireLogin : true,
          mainNavId : 2,
          wrapClass : ['m-sub', 'm-nav-none', 'basic'],
          pageTitle: '서비스 예약',
          breadcrumb: [
            { text: '홈',},
            { text: '서비스 예약',},
            {
              text: '방문 예약일시 선택',
              active: true,
            },
          ],
        },
      },
      {
        path: 'service/reservation/serviceCheck',
        name: 'sonax-user-reservationServiceCheck',
        component: () => import('@/views/sonax-user/service/reservation/ServiceCheck.vue'),
        meta: {
          requireLogin : true,
          mainNavId : 2,
          wrapClass : ['m-sub', 'm-nav-none', 'basic'],
          pageTitle: '서비스 예약',
          breadcrumb: [
            { text: '홈',},
            { text: '서비스 예약',},
            {
              text: '서비스 상품 확인',
              active: true,
            },
          ],
        },
      },
      {
        path: 'service/reservation/payment',
        name: 'sonax-user-reservationPayment',
        component: () => import('@/views/sonax-user/service/reservation/Payment.vue'),
        meta: {
          requireLogin : true,
          mainNavId : 2,
          wrapClass : ['m-sub', 'm-nav-none', 'basic'],
          pageTitle: '서비스 예약',
          breadcrumb: [
            { text: '홈',},
            { text: '서비스 예약',},
            {
              text: '결제 및 예약',
              active: true,
            },
          ],
        },
      },
      {
        path: 'service/reservation/completed',
        name: 'sonax-user-reservationCompleted',
        component: () => import('@/views/sonax-user/service/reservation/Completed.vue'),
        props: (route) => ({ orderNum: route.query.orderNum }),
        meta: {
          requireLogin : true,
          mainNavId : 2,
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '서비스 예약',
          breadcrumb: [
            { text: '홈',},
            { text: '서비스 예약',},
            {
              text: '예약 완료',
              active: true,
            },
          ],
        },
      },
      {
        path: 'service/reservation/failed',
        name: 'sonax-user-reservationFailed',
        component: () => import('@/views/sonax-user/service/reservation/Failed.vue'),
        props: (route) => ({ orderNum: route.query.orderNum , errorMsg:route.query.errorMsg }),
        meta: {
          requireLogin : true,
          mainNavId : 2,
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '서비스 예약',
          breadcrumb: [
            { text: '홈',},
            { text: '서비스 예약',},
            {
              text: '예약 실패',
              active: true,
            },
          ],
        },
      },



      {
        path: 'service/partner/service',
        name: 'sonax-user-servicePartner',
        component: () => import('@/views/sonax-user/service/partner/PartnerService.vue'),
        meta: {          
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '제휴 서비스',
          breadcrumb: [
            {text: '홈',},
            {text: '서비스',},
            {
              text: '제휴 서비스',
              active: true,
            },
          ],
        },        
      },
      {
        path: 'service/partner/service/detail',
        name: 'sonax-user-servicePartner-detail',
        component: () => import('@/views/sonax-user/service/partner/PartnerServiceDetail.vue'),
        props: (route) => { 
          const ptnEventId = Number.parseInt(route.query.ptnEventId)
          return {ptnEventId}
        },
        meta: {          
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '제휴 서비스 상세',
          breadcrumb: [
            {text: '홈',},
            {text: '서비스',},
            {
              text: '제휴 서비스 상세',
              active: true,
            },
          ],
        },        
      },
      {
        path: 'service/info',
        name: 'sonax-user-serviceInfo',
        component: () => import('@/views/sonax-user/service/ServiceInfo.vue'),
        meta: {
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '서비스 소개',
          breadcrumb: [
            {text: '홈',},
            {text: '서비스',},
            {
              text: '서비스 소개',
              active: true,
            },
          ],
        },        
      },
      {
        path: 'service/store/find',
        name: 'sonax-user-serviceStoreFind',
        component: () => import('@/views/sonax-user/service/store/FindStore.vue'),
        meta: {
          wrapClass : ['m-sub', 'm-nav-none', 'basic'],
          pageTitle: '서비스점 보기',
          breadcrumb: [
            {text: '홈',},
            {text: '서비스',},
            {
              text: '서비스점 보기',
              active: true,
            },
          ],
        },        
      },
      {
        path: 'service/store/detail',
        name: 'sonax-user-serviceStoreDetail',
        component: () => import('@/views/sonax-user/service/store/StoreDetail.vue'),
        props: (route) => { 
          const frSeq = Number.parseInt(route.query.frSeq, 10)
          if(Number.isNaN(frSeq)){
            return {frSeq : 0};
          }
          return {frSeq}
        },
        meta: {
          wrapClass : ['m-sub', 'm-nav-none', 'basic'],
          pageTitle: '서비스점 상세',
          breadcrumb: [
            {text: '홈',},
            {text: '서비스',},            
            {
              text: '서비스점 상세',
              active: true,
            },
          ],
        },        
      },
    ]
  },  
]

