<template>
  <header class="app-header">
    <div class="header">
      <div class="header-wrap">
        <div class="logo" @click.prevent="onClickHome"></div>
        <div class="header-r">
          <div v-show="!isLoggedIn" class="header-user">            
            <li><a href="#" @click.prevent="onClickLogin">로그인</a></li>
            <li><a href="#" @click.prevent="onClickJoin">회원가입</a></li>
          </div>
          <div v-show="isLoggedIn" class="header-user">            
            <li><a href="#" @click.prevent="onClickLogout">로그아웃</a></li>            
          </div>
          <div class="header-user header-noti">
            <li><a href="#" @click.prevent="onClickNotice">공지사항</a></li>
            <li><a href="#" @click.prevent="onClickCouponList">쿠폰&혜택</a></li>
            <li v-show="isLoggedIn"><a href="#" @click.prevent="onClickNotification">알림<span
              v-show="noCheckNotiCount > 0">{{noCheckNotiCount}}</span></a>
            </li>
          </div>
        </div>
      </div>
    </div>
    <div class="nav">
      <li class="pc-none"><a href="#" @click.prevent="onClickHome"
        :class="{'active' : isActive(1)}" class="main-ic home-ic">홈</a></li>
      <li><a href="#" @click.prevent="onClickServiceReservation"
        :class="{'active' : isActive(2)}" class="main-ic reserve-ic">예약</a></li>
      <li><a href="#" @click.prevent="onClickMyPage"
        :class="{'active' : isActive(3)}" class="main-ic mypage-ic">마이페이지</a></li>
      <li><a href="#" @click.prevent="onClickCustomerService"
        :class="{'active' : isActive(4)}" class="main-ic customer-ic">고객센터</a></li>
    </div>


  </header>
</template>

<script>
import ernsUtils from '@/components/mixins/ernsUtils';

export default {
  name: 'AppHeader',
  mixins: [ernsUtils],
  computed: {    
    isLoggedIn : function(){
      return this.$store.state.erns.userInfo != null;
    },
    noCheckNotiCount(){
      return this.$store.state.erns.noCheckNotiCount;
    },
  },
  data(){
    return {
      
    }
  },  
  mounted() {
    const _vm = this;
    //_vm.setNotiUnCheckBadge();
  },
  methods:{
    isActive(menuId){
      const _vm = this;
      const navId = _vm.$route.meta.mainNavId;
      const mainNavId = navId || 0;
      return menuId == mainNavId;      
    },
    
    onClickHome(){
      const _vm = this;
      _vm.ermReplacePage({name:'sonax-user-main'});
    },

    onClickServiceReservation(){            
      const _vm = this;
      _vm.ermPushPage({name:'sonax-user-serviceMain'});
    },

    onClickMyPage(){
      const _vm = this;
      //mypage.html
      _vm.ermPushPage({name:'sonax-user-mypageMain'});
    },

    onClickCustomerService(){
      const _vm = this;
      //customer.html
      _vm.ermPushPage({name:'sonax-user-cmMain'});
    },

    onClickLogin(){
      const _vm = this;
      _vm.ermPushPage({name:'sonax-user-login'});
    },

    onClickJoin(){
      const _vm = this;      
      _vm.$store.dispatch('erns/setJoinData', null);
      _vm.ermPushPage({name:'sonax-user-join-select'});
    },

    //로그아웃
    onClickLogout(){
      const _vm = this;

      _vm.eumLogout();    
    },
    onClickNotice(){
      const _vm = this;
      //공지사항 APP modal 방식
      _vm.ermStackPage({path:'/sonax/notice'}, (message)=>{
        if(_vm.isAppDevice && window.sonax_app){
          // window.sonax_app.showToast(`공지사항 종료:${message}`);
          return;
        }
      }, "client Message!!!");
    },

    onClickNotification(){
      //push.html
      const _vm = this;
      //Push APP modal 방식
      _vm.ermStackPage({path:'/sonax/push'}, (message)=>{
        if(_vm.isAppDevice && window.sonax_app){
          // window.sonax_app.showToast(`push 종료:${message}`);
          return;
        }
      }, "client Message!!!");
    },

    onClickCouponList() {
      const _vm = this;

      _vm.ermStackPage({path:'/sonax/coupon'}, (message)=>{
        if(_vm.isAppDevice && window.sonax_app){
          // window.sonax_app.showToast(`push 종료:${message}`);
          return;
        }
      }, "client Message!!!");
    }
  }
}
</script>

<style lang="scss" scoped>
.app-header {
  
}
</style>

<style lang="scss">
</style>
