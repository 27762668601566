//Layout
import BasicLayout from '@/layouts/BasicLayout';

export default [
  {
    path: '/sonax',
    component: BasicLayout,
    children: [
      {
        path: 'notice/preview',
        name: 'sonax-user-notice-preview',
        component: () => import('@/views/sonax-user/notice/NoticePreview.vue'),
        // props: (route) => {
        //   return { data: route.params.previewData }
        // },
        meta: {          
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '공지사항',
          breadcrumb: [
            { text: '홈',},
            {
              text: '공지사항 미리보기',
              active: true,
            },
          ],
        },
      },
    ]
  },  
]

