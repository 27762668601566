//Layout
import BasicLayout from '@/layouts/BasicLayout';

export default [
  {
    path: '/sonax',
    component: BasicLayout,
    children: [
      {
        path: 'mypage/main',
        name: 'sonax-user-mypageMain',
        component: () => import('@/views/sonax-user/MyPageMain.vue'),
        meta: {
          requireLogin : true,
          mainNavId : 3,
          wrapClass : ['m-sub'],
          pageTitle: '마이페이지',
          breadcrumb: [
            {text: '홈',},
            {
              text: '마이페이지',
              active: true,
            },
          ],          
        },
      },
      {
        path: 'mypage/myInfo',
        name: 'sonax-user-mypage-myInfo',
        component: () => import('@/views/sonax-user/myPage/MyInfo.vue'),
        meta: {
          requireLogin : true,
          mainNavId : 3,
          wrapClass : ['m-sub','m-nav-none'],
          pageTitle: '내정보 관리',
          breadcrumb: [
            {text: '홈',},
            {text: '마이페이지',},
            {
              text: '내정보 관리',
              active: true,
            },
          ],        
        },
      },
      {
        path: 'mypage/point',
        name: 'sonax-user-mypage-point',
        component: () => import('@/views/sonax-user/myPage/MyPoint.vue'),
        meta: {
          requireLogin : true,
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '적립금 내역',
          breadcrumb: [
            {text: '홈',},
            {text: '마이페이지',},
            {
              text: '적립금 내역',
              active: true,
            },
          ],
        },        
      },
      {
        path: 'mypage/coupon',
        name: 'sonax-user-mypage-coupon',
        component: () => import('@/views/sonax-user/myPage/MyCoupon.vue'),
        props: (route) => ({ 
          couponNum: route.query.couponNum,
          download: route.query.download,
          couponMstId: route.query.couponMstId
        }),
        meta: {
          requireLogin : true,
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '쿠폰관리',
          breadcrumb: [
            {text: '홈',},
            {text: '마이페이지',},
            {
              text: '쿠폰관리',
              active: true,
            },
          ],
        },
      },
      {
        path: 'mypage/serviceHistory',
        name: 'sonax-user-mypage-serviceHistory',
        component: () => import('@/views/sonax-user/myPage/MyServiceHistory.vue'),
        meta: {
          requireLogin : true,
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '이용내역',
          breadcrumb: [
            {text: '홈',},
            {text: '마이페이지',},
            {
              text: '이용내역',
              active: true,
            },
          ],
        },
      },
      {
        path: 'mypage/serviceHistoryDetail',
        name: 'sonax-user-mypage-serviceHistoryDetail',
        component: () => import('@/views/sonax-user/myPage/MyServiceHistoryDetail.vue'),
        props: (route) => ({ orderNum: route.query.orderNum }),
        meta: {
          requireLogin : true,
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '이용내역',
          breadcrumb: [
            {text: '홈',},
            {text: '마이페이지',},
            {text: '이용내역',},
            {
              text: '이용내역 상세',
              active: true,
            },
          ],
        },
      },

      {
        path: 'mypage/review',
        name: 'sonax-user-mypage-review',
        component: () => import('@/views/sonax-user/myPage/MyReview.vue'),
        meta: {
          requireLogin : true,
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '리뷰관리',
          breadcrumb: [
            {text: '홈',},
            {text: '마이페이지',},
            {
              text: '리뷰관리',
              active: true,
            },
          ],
        },
      },
      {
        path: 'mypage/review/form',
        name: 'sonax-user-mypage-review-form',
        component: () => import('@/views/sonax-user/myPage/MyReviewForm.vue'),
        props: (route) => ({ orderNum: route.query.orderNum }),
        meta: {
          requireLogin : true,
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '리뷰관리',
          breadcrumb: [
            {text: '홈',},
            {text: '마이페이지',},
            {
              text: '리뷰작성',
              active: true,
            },
          ],
        },
      },
      {
        path: 'mypage/inquiry',
        name: 'sonax-user-mypage-inquiry',
        component: () => import('@/views/sonax-user/myPage/MyInquiry.vue'),
        meta: {
          requireLogin : true,
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '내문의내역',
          breadcrumb: [
            {text: '홈',},
            {text: '마이페이지',},
            {
              text: '내문의내역',
              active: true,
            },
          ],
        },
      },
      {
        path: 'mypage/payment',
        name: 'sonax-user-mypage-payment',
        component: () => import('@/views/sonax-user/myPage/MyPayment.vue'),
        meta: {
          requireLogin : true,
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '카드관리',
          breadcrumb: [
            {text: '홈',},
            {text: '마이페이지',},
            {
              text: '카드관리',
              active: true,
            },
          ],
        },
      },
      {
        path: 'mypage/setting',
        name: 'sonax-user-mypage-setting',
        component: () => import('@/views/sonax-user/myPage/MySetting.vue'),
        meta: {
          requireLogin : true,
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '환경설정',
          breadcrumb: [
            {text: '홈',},
            {text: '마이페이지',},
            {
              text: '환경설정',
              active: true,
            },
          ],
        },        
      }
    ]
  },  
]

