import format from 'format-number';


const fncFmtCurrency = function(value, prefix, suffix){
  const s = suffix || '';
  const cFormat = format({prefix: prefix, suffix: s});
  return cFormat(value, {noSeparator: false});
};

const fncFmtNumber = function(value, round, truncate, prefix, suffix){
  const s = suffix || '';
  const p = prefix || '';
  const op = {
    prefix: p, suffix: s
  };
  if(round != null){
    op.round = round;
  }
  if(truncate != null){
    op.truncate = truncate;
  }  
  const cFormat = format(op);
  return cFormat(value, {noSeparator: false});
};

/**
 *  예제
 *  {{12312312 | eFmtWon}}<br/>
    {{12312312.12 | eFmtWon}}<br/>
    {{12312312.12 | eFmtDolar}}<br/>
    {{12312312.12 | eFmtNum}} <br/> 
    {{12312312.167 | eFmtNumT(2)}} <br/> 
    {{12312312.168 | eFmtNumR(2)}} <br/> 
 */
export default {
  data() {
    return {

    }
  },
  filters: {
    //소수 반올림
    eFmtNumR(value, round, prefix, suffix){
      const r = round || 0;
      return fncFmtNumber(value, r, null, prefix, suffix);
    },

    //소수 버림
    eFmtNumT(value, truncate, prefix, suffix){
      const t = truncate || 0;
      return fncFmtNumber(value, null, t, prefix, suffix);
    },

    //통화
    eFmtCurrency(value, prefix, suffix){
      return fncFmtCurrency(value, prefix, suffix);
    },

    //원화
    eFmtWon(value, suffix) {
      return fncFmtCurrency(value, '₩', suffix);
    },

    //달러
    eFmtDolar(value, suffix) {
      return fncFmtCurrency(value, '$', suffix);
    },
    
    //리뷰 별점 평균값 (5단위절사)
    eFmtReview(val) {
      if(!val){
        return 0;
      }

      return Math.round(val.toFixed(1)) * 10;
    },
    
    //리뷰 별점 평균값 (소수점 아래 1자리 출력)
    eFmtReviewPoint(val) {
      if(!val){
        return "0.0";
      }

      return val.toFixed(1);
    },
  },
  methods: {
    
    
  }
}