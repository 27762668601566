import "core-js/stable";

import Vue from 'vue'

//import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import VueCookie from 'vue-cookie'

//Vue Cookie
Vue.use(VueCookie)

// 3rd party plugins
import '@axios'
import '@/libs/sweet-alerts'

// VeeValidate locale 설정
import {ValidationProvider, ValidationObserver, localize} from 'vee-validate';
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
localize('ko');

//VueClipboard
import VueClipboard from 'vue-clipboard2' 
Vue.use(VueClipboard);

// Fragment
import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin);

// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);

// Modal
import VModal from 'vue-js-modal/dist/index.nocss.js'
import 'vue-js-modal/dist/styles.css'
Vue.use(VModal)

// import core styles
require('@/assets/css/reset.css')
require('@/assets/css/style.css')
require('@/assets/css/swiper.min.css')
require('@/assets/css/animate.css')
require('@/assets/css/chosen.css')
require('@/assets/css/metro-all.css')


// import assets styles
require('@/assets/scss/style.scss')

//아임포트 본인인증
import IMP from 'vue-iamport';
Vue.use(IMP, 'imp39848633') //아임포트 회원가입 후 발급된 가맹점 고유 코드를 사용해주세요.
Vue.IMP().load()

Vue.config.productionTip = false

import loading from '@/components/mixins/loading';
import device from '@/components/mixins/device';
const vm = new Vue({
  mixins: [loading, device],
  router,
  store,
  //i18n,
  render: h => h(App),
}).$mount('#app')

window.sonax_user_vue = vm;
