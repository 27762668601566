const SHOW_ROWS = [5, 15, 30, 50, 100];

import sonaxConfig from '@/config'

const SYSTEM_SERVICE_CURRENT_VERSION = process.env.SYSTEM_SERVICE_CURRENT_VERSION;

export default {
  namespaced: true,
  state: {
    systemServiceCurrentVersion : SYSTEM_SERVICE_CURRENT_VERSION, //서비스 버전

    deviceType : "WEB", //접속 device
    deviceTypeNative : "android", //접속 android or ios
    userInfo : null,    //사용자 프로필     
    joinData : null,    //회원가입 데이터
    reservationData : null, //예약 데이터
    gisInfo : {         //위치데이터
      isLoading : false,
      tryCount : 0,      
      loadingTime : new Date(),      
      latitude : 37.5535256980589,
      longitude : 127.194669378414,    

      areaCode : "4145010800", //덕풍동
      addressName: "경기도 하남시 덕풍3동",
      region1depthName: "경기도",
      region2depthName: "하남시",
      region3depthName: "덕풍3동",

      error : true,
    },

    showRows : SHOW_ROWS,    
    loading : {
      isLoading : false,
      mode : 'spinner', //spinner, progress
      progress : {
        animated : true,
        showProgress : true,
        showValue : true,
        striped : true,
        height : "calc(4vh)",
        max : 100,
        precision : 0,
        value : 0,
        variant : "danger" //"success"
      },
      spinner : {
        label : "Spinning",
        loader : null,
      }
    },
    routerHistoryMap : {},
    noCheckNotiCount : 0,

    pushTokenInfo : {
      token : '',
      transDate : 0,
      transToken : '',
    },

    reservationParam : {
      frSeq : 0,
      frName : '미정의',
      state : 0
    }
  },
  getters: {
    deviceType : state => state.deviceType,
    userInfo : state => state.userInfo,    
    joinData : state => state.joinData,
    reservationData : state => state.reservationData,
    gisInfo : state => state.gisInfo,

    showRows: state => state.showRows,
    loading : state => state.loading,
    routerHistoryMap : state => state.routerHistoryMap,
    noCheckNotiCount : state => state.noCheckNotiCount,
    pushTokenInfo : state => state.pushTokenInfo,
    reservationParam : state => state.reservationParam,
  },
  mutations: {
    SET_USERINFO : (state, userInfo) => {
      state.userInfo = userInfo;
    },
    SET_JOIN_DATA : (state, joinData) => {
      state.joinData = joinData;
    },
    SET_RESERVATION_DATA : (state, reservationData) => {
      state.reservationData = reservationData;
    },
    SET_GIS_INFO : (state, gisInfo) => {
      state.gisInfo = gisInfo;
    },

    CHANGE_OPTION: (state, { key, value }) => {
      // eslint-disable-next-line no-prototype-builtins
      if (state.hasOwnProperty(key)) {
        state[key] = value
      }
    },
    SET_LOADING : (state, loading) => {
      state.loading = loading;
    },
    SET_ROUTER_HISTORY_MAP : (state, {key, value}) => {
      state.routerHistoryMap[key] = value;
    },
    SET_NO_CHECK_NOTI_COUNT : (state, value) => {
      state.noCheckNotiCount = value;
    },
    SET_PUSH_TOKEN_INFO : (state, value) => {
      state.pushTokenInfo = value;
    },
    SET_RESERVATION_PARAM : (state, value) => {
      state.reservationParam = value;
    },
  },
  actions: {
    setUserInfo( {commit}, userInfo) {
      commit('SET_USERINFO', userInfo)
    },
    setJoinData( {commit}, joinData) {
      commit('SET_JOIN_DATA', joinData)
    },
    setReservationData( {commit}, reservationData) {
      //save local storage
      localStorage.setItem(sonaxConfig.storageReservationDataKey, JSON.stringify(reservationData));
      
      commit('SET_RESERVATION_DATA', reservationData);
    },
    setGisInfo( {commit}, gisInfo) {
      commit('SET_GIS_INFO', gisInfo)
    },


    changeOption({ commit }, data) {
      commit('CHANGE_OPTION', data)
    },
    setLoading( {commit}, loading) {
      commit('SET_LOADING', loading)
    },
    setRouterHistoryMap( {commit}, hisData) {
      commit('SET_ROUTER_HISTORY_MAP', hisData)
    },
    setNoCheckNotiCount( {commit}, value) {
      commit('SET_NO_CHECK_NOTI_COUNT', value)
    },
    setPushTokenInfo( {commit}, pushTokenInfo) {
      //save local storage
      localStorage.setItem(sonaxConfig.storagePushTokenKey, JSON.stringify(pushTokenInfo));
      
      commit('SET_PUSH_TOKEN_INFO', pushTokenInfo);
    },

    setReservationParam( {commit}, pushTokenInfo) {
      //save local storage
      localStorage.setItem('sonax.reservationParam', JSON.stringify(pushTokenInfo)); //새로고침 시 초기화 방지
      
      commit('SET_RESERVATION_PARAM', pushTokenInfo);
    },
  },
}
