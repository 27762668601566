import request from '@/utils/request'

const getCalendar = async function(params) {
    return request({
        url: '/api/user/reservation/getCalendar.api',
        method: 'post',
        data: params
    });
}

const getTimeBox = async function(params) {
    return request({
        url: '/api/user/reservation/getTimeBox.api',
        method: 'post',
        data: params
    });
}

const simulateReservation = async function(params) {
    return request({
        url: '/api/user/reservation/simulate.api',
        method: 'post',
        data: params
    });
}

const addReservation = async function(params) {
    return request({
        url: '/api/user/reservation/add.api',
        method: 'post',
        data: params
    });
}

const getReservationDetail = async function(params) {
    return request({
        url: '/api/user/reservation/getDetail.api',
        method: 'post',
        params: params
    });
}

const getReservationList = async function(params) {
    return request({
        url: '/api/user/reservation/list.api',
        method: 'post',
        data: params
    });
}

const getFrList = async function(params) {
    return request({
        url: '/api/user/reservation/getFrList.api',
        method: 'post',
        data: params
    });
}
const getVehList = async function(params) {
    return request({
        url: '/api/user/reservation/getVehList.api',
        method: 'post',
        data: params
    });
}

const setRsvCancel = async function(params) {
    return request({
        url: '/api/user/reservation/setRsvCancel.api',
        method: 'post',
        data: params
    });
}

const setRsvChange = async function(params) {
    return request({
        url: '/api/user/reservation/setRsvChange.api',
        method: 'post',
        data: params
    });
}

const getListMyPage = async function(params) {
    return request({
        url: '/api/user/reservation/getListMyPage.api',
        method: 'post',
        data: params
    });
}

const getRsvLogList = async function(params) {
    return request({
        url: '/api/user/reservationLog/list.api',
        method: 'post',
        data: params
    });
}

const getPaymentByReservationId = async function(params) {
    return request({
        url: '/api/user/reservation/getPaymentByReservationId.api',
        method: 'post',
        data: params
    });
}

const loadFiles = async function(params) {
    return request({
        url: '/api/user/reservation/loadFiles.api',
        method: 'post',
        data: params
    });
}

const selectChecklist = async function(params) {
    return request({
        url: '/api/user/reservation/checklist/list.api',
        method: 'post',
        params: params
    });
}

const getCmmCalendar = async function(params) {
    return request({
        url: '/api/cmm/reservation/getCalendar.api',
        method: 'post',
        data: params
    });
}

export {
    getCalendar,
    getTimeBox,
    simulateReservation,
    addReservation,
    getReservationDetail,
    getReservationList,
    getFrList,
    getVehList,
    setRsvCancel,
    setRsvChange,
    getListMyPage,
    getRsvLogList,
    getPaymentByReservationId,

    loadFiles,
    selectChecklist,
    getCmmCalendar,
}
