//Layout
import BasicLayout from '@/layouts/BasicLayout';

export default [
  {
    path: '/sonax',
    component: BasicLayout,
    children: [
      {
        path: 'join/select',
        name: 'sonax-user-join-select',
        component: () => import('@/views/sonax-user/join/JoinSelect.vue'),
        meta: {
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '회원가입',
          breadcrumb: [
            { text: '홈',},
            { text: '회원가입', active: true,},            
          ],
        },
      },
      {
        path: 'join',
        name: 'sonax-user-join',
        component: () => import('@/views/sonax-user/join/Join.vue'),
        meta: {
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '약관동의',
          breadcrumb: [
            { text: '홈',},
            { text: '회원가입',},
            {
              text: '약관동의',
              active: true,
            },
          ],
        },
      },
      {
        path: 'join/editForm1',
        name: 'sonax-user-joinForm1',
        component: () => import('@/views/sonax-user/join/EditForm1.vue'),
        meta: {
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '필수정보',
          breadcrumb: [
            { text: '홈', },
            { text: '회원가입',},
            {
              text: '필수정보',
              active: true,
            },
          ],
        },
      },
      {
        path: 'join/editForm2',
        name: 'sonax-user-joinForm2',
        component: () => import('@/views/sonax-user/join/EditForm2.vue'),
        meta: {
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '정보입력',
          breadcrumb: [
            { text: '홈',},
            { text: '회원가입',},
            {
              text: '정보입력',
              active: true,
            },
          ],
        },
      },

      /*
      {
        path: 'join/confirm',
        name: 'sonax-user-join-confirm',
        component: () => import('@/views/sonax-user/join/Confirm.vue'),
        meta: {
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '본인인증',
          breadcrumb: [
            { text: '홈', },
            { text: '회원가입',},
            {
              text: '본인인증',
              active: true,
            },
          ],
        },
      },
      */
    ]
  }
]

