import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

//Sonax BackOffice
import sonaxUser from './routes/sonax-user';
import pages from './routes/pages';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'sonax-user-main' } },

    ...sonaxUser,

    // ...apps,
    ...pages,
    // ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

const isRequireLogin = function(to){
  const requireLogin = false || to.meta.requireLogin;
  return requireLogin;
}

router.beforeEach((to, from, next) => {
  
  const isLoggedIn = isUserLoggedIn();
  
  //console.debug(to);
  if (!isLoggedIn && isRequireLogin(to)) {
    // Redirect to login if not logged in
    return next({ name: 'sonax-user-login', params: {
      retRoute : to
    }});
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(null));
  }

  return next();
});

router.afterEach((to, from)=>{  
});

export default router
