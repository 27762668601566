const appKey = process.env.VUE_APP_KAKAO_JSKEY;

const getPosition = function(options) {
  return new Promise((resolve, reject) => 
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  );
}

const getCoord2RegionCode = function(coords) {
  return new Promise((resolve, reject) => {
    const {latitude, longitude} = coords;
    try{
      const geocoder = new kakao.maps.services.Geocoder();
      geocoder.coord2RegionCode(longitude, latitude, resolve);

    }catch(err){
      reject(err);
    }
  });
}

export default {
  mixins: [],
  data() {
    return {

    }
  },
  computed: {
    
  },
  filters :{
    eufDistance(distance) {
      if(distance == null){
        return "-";
      }

      if(distance >= 1){
        return distance.toFixed(2) + " km";
      }

      distance = distance*1000;
      return distance.toFixed(0) + " m";
    }
  },
  methods: {
    // 두 좌표사이 거리 구하기
    eumGetDistance(lat1, lng1, lat2, lng2) {
      const _vm = this;
      
      function deg2rad(deg) {
        return deg * (Math.PI/180);
      }
      var r = 6371; //지구의 반지름(km)
      var dLat = deg2rad(lat2-lat1);
      var dLon = deg2rad(lng2-lng1);
      var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon/2) * Math.sin(dLon/2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      var d = r * c; // Distance in km
      
      return _vm.$options.filters.eufDistance(d);
    },

    //GPS 가져오기
    async eumGetLocation(forceLoad) {
      const _vm = this;

      const oldGisInfo = _vm.$store.state.erns.gisInfo;
      if(!forceLoad && oldGisInfo.isLoading){
        //로드 할 필요 없을 경우, 바로 리턴
        return oldGisInfo;
      }

      if(!forceLoad && oldGisInfo.tryCount > 0){
        return oldGisInfo;
      }

      const retGisInfo = {
        isLoading : false,
        tryCount : oldGisInfo.tryCount,
        loadingTime : new Date(),
        
        latitude : 37.5535256980589,
        longitude : 127.194669378414,    

        areaCode : "4145010800", //덕풍동
        addressName: "경기도 하남시 덕풍3동",
        region1depthName: "경기도",
        region2depthName: "하남시",
        region3depthName: "덕풍3동",

        error : true,
      };

      retGisInfo.tryCount++;

      if (navigator.geolocation) { 
        // GPS를 지원하면
        try{
          const position = await getPosition({
            enableHighAccuracy: false,
            maximumAge: 0,
            timeout: 3000
          });

          retGisInfo.latitude = position.coords.latitude;
          retGisInfo.longitude = position.coords.longitude;
          retGisInfo.isLoading = true;
          retGisInfo.loadingTime = new Date();
          retGisInfo.error = false; 
        }catch(err){
          
          //권한거부등 
          console.error(err);
        }        
      } 

      try{
        const regionCodes = await getCoord2RegionCode(retGisInfo);
        //console.debug(regionCodes);
        if(regionCodes && regionCodes.length > 0){
          const retRc = regionCodes[0];

          retGisInfo.areaCode = retRc.code;
          retGisInfo.addressName = retRc.address_name;
          retGisInfo.region1depthName = retRc.region_1depth_name;
          retGisInfo.region2depthName = retRc.region_2depth_name;
          retGisInfo.region3depthName = retRc.region_3depth_name;
        }
        
      }catch(err){
        console.error(err);
      } 

      _vm.$store.dispatch('erns/setGisInfo', retGisInfo); 
      //console.debug(retGisInfo);

      return retGisInfo;
    },

    eumLoadKakaoLib(pCallback) {
      const _vm = this;
      const callback = typeof(pCallback) == 'function' ? pCallback : function(){};

      // load src (kakao maps)
      if (window.kakao && window.kakao.maps) {
        callback.call(_vm);
        return;
      } 

      const script = document.createElement('script');
      script.async = true;
      script.src =
        "//dapi.kakao.com/v2/maps/sdk.js?libraries=services&autoload=false&appkey="+appKey;
      
      const onLoadKakaoMap = () => {
        window.kakao.maps.load(() => {
          callback.call(_vm);
        });
      };
      script.addEventListener("load", onLoadKakaoMap);
      document.head.appendChild(script);
    }
  }
}
