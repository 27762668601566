//Layout
import BasicLayout from '@/layouts/BasicLayout';

export default [
  {
    path: '/sonax',
    component: BasicLayout,
    children: [
      {
        path: 'test',
        name: 'sonax-user-map-test',
        component: () => import('@/views/sonax-user/MapTest.vue'),
        meta: {
          pageTitle: '맵테스트',
          breadcrumb: [
            {
              text: '홈',
            },
            {
              text: '맵테스트',
              active: true,
            },
          ],
        },
      },
      {
        path: 'dtest',
        name: 'sonax-user-dialog-test',
        component: () => import('@/views/sonax-user/DialogTest.vue'),
        meta: {
          pageTitle: '테스트',
          breadcrumb: [
            {
              text: '홈',
            },
            {
              text: '테스트',
              active: true,
            },
          ],
        },
      }
    ]
  },  
]

