import jwtDefaultConfig from './jwtDefaultConfig'
import Vue from 'vue'

class ApiError extends Error {
  constructor(message = "", ...args) {
    super(message, ...args);
    this.response = args[0];
  }
}

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => {        
        return this.onResponseHandler(response);
      },
      async error => {
        const _vm = this;
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config
        //console.error(error);

        if (response && response.status === 401) {
          const {data:res} = response;
          // console.error(error);
          // console.info(response);
          
          if(res.code !== "10401"){
            //console.info(response);
            await _vm.forceLogoutInvalidAccess();
            return Promise.reject(error);
          }

          if (!_vm.isAlreadyFetchingAccessToken) {
            _vm.isAlreadyFetchingAccessToken = true;
            _vm.refreshToken().then(r => {
              _vm.isAlreadyFetchingAccessToken = false;

              // Update accessToken in localStorage
              const {result, objReturnData} = r;
              if(result == "S"){
                _vm.setToken(objReturnData.accessToken);
                _vm.setRefreshToken(objReturnData.refreshToken);
                _vm.onAccessTokenFetched(objReturnData.accessToken);
              }else{
                console.error("Cannot refresh token");
                throw 'Cannot refresh token';
              }
            }).catch(err=>{
              _vm.forceLogout();
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            _vm.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${_vm.jwtConfig.tokenType} ${accessToken}`
              resolve(_vm.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }

        if(config.url == _vm.jwtConfig.refreshEndpoint){
          await _vm.forceLogout();
        }

        return Promise.reject(error)
      },
    )
  }

  async onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken));

    //Push token 처리
    const _vueMainVm = window.sonax_user_vue;
    if(_vueMainVm && _vueMainVm.ernSendPushToken){
      await _vueMainVm.ernSendPushToken();
    }
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  async logout(){
    const accessToken = this.getToken();
    const refreshToken = this.getRefreshToken();

    let resLogout = null;
    try{
      //서버에 토큰 BlackList 로 처리요청
      resLogout = await this.axiosIns.post(this.jwtConfig.logoutEndpoint, {
        accessToken : accessToken
        , refreshToken : refreshToken
      });
    }catch(err){
      console.error(err);
    }
    
    // Remove Token
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)

    // Remove userData from localStorage
    localStorage.removeItem(this.jwtConfig.storageUserDataKeyName)

    return resLogout;    
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      accessToken : this.getToken(),
      refreshToken: this.getRefreshToken(),
    })
  }

  async forceLogout(){
    const _vm = this;

    const _vueMainVm = window.sonax_user_vue;
    if(_vueMainVm && _vueMainVm.loading){
      _vueMainVm.loading(false);
    }

    /*
    await Vue.swal({
      title : "오류",
      //text: res.returnMessage || '권한이 없습니다.',
      html: "로그 아웃되었습니다. <br/>로그인 화면으로 이동합니다.",
      icon: 'error',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
    });
    */
    _vueMainVm.$store.dispatch('erns/setUserInfo', null);  //회원정보
    _vueMainVm.$store.dispatch('erns/setReservationData', null); //예약정보
    _vueMainVm.$store.dispatch('erns/setJoinData', null); //회원가입정보

    //로그 아웃 처리
    await _vm.logout();
    window.location.href = _vm.jwtConfig.loginPage;
  }

  async forceLogoutInvalidAccess(){
    const _vm = this;

    const _vueMainVm = window.sonax_user_vue;
    if(_vueMainVm && _vueMainVm.loading){
      _vueMainVm.loading(false);
    }

    //로그 아웃 처리
    await _vm.logout();
    _vueMainVm.$store.dispatch('erns/setUserInfo', null);  //회원정보
    _vueMainVm.$store.dispatch('erns/setReservationData', null); //예약정보
    _vueMainVm.$store.dispatch('erns/setJoinData', null); //회원가입정보


    // await Vue.swal({
    //   title : "오류",
    //   html: "권한이 없습니다.<br/>로그인 화면으로 이동합니다.",
    //   icon: 'error',
    //   customClass: {
    //     confirmButton: 'btn btn-primary',
    //   },
    //   buttonsStyling: false,
    // });
    
    window.location.href = _vm.jwtConfig.loginPage;
  }

  async onResponseHandler(response){
    const _vm = this;
    const {config, data:res} = response;
    const {result} = res;

    if(response.data instanceof Blob){
      return response;
    }

    if(result == null || result == undefined){
      return Promise.reject(new Error('통신오류가 발생하였습니다.'));
    }

    // 만약 reuslt 가 "S" 가 아니면 오류
    
    if (result != "S") {
      //console.debug(response);

      // 10401: Token expired;
      // 10402: Illegal token;
      // 10403: Token is blockd;
      if (config.url == _vm.jwtConfig.refreshEndpoint || res.code === "10401" || res.code === "10402" || res.code === "10403") {
        // to re-login
        await _vm.forceLogout();
      }

      if (res.code === "10402" || res.code === "10403") {
        // to re-login
        await _vm.forceLogout();
      }

      return Promise.reject(new ApiError(res.returnMessage || '알 수 없는 오류가 발생하였습니다.'
        , response
      ));
      //return Promise.reject(new Error(res.returnMessage || '알 수 없는 오류가 발생하였습니다.'));
    } else {
      return res
    }
  }
}
