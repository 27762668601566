//Layout
import BasicLayout from '@/layouts/BasicLayout';

export default [
  {
    path: '/sonax',
    component: BasicLayout,
    children: [
      {
        path: 'main',
        name: 'sonax-user-main',
        component: () => import('@/views/sonax-user/Main.vue'),
        meta: {
          mainNavId : 1,
          wrapClass : [],
          pageTitle: '홈',
          breadcrumb: [
            {
              text: '홈',
              active: true,
            },
          ],
        },
      },
      {
        path: 'notice',
        name: 'sonax-user-notice',
        component: () => import('@/views/sonax-user/notice/Notice.vue'),
        meta: {          
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '공지사항',
          breadcrumb: [
            { text: '홈',},
            {
              text: '공지사항',
              active: true,
            },
          ],
        },
      },
      {
        path: 'notice/detail',
        name: 'sonax-user-notice-datail',
        component: () => import('@/views/sonax-user/notice/NoticeDetail.vue'),
        props: (route) => ({ boardId: Number.parseInt(route.query.boardId, 10) }),
        meta: {          
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '공지사항',
          breadcrumb: [
            { text: '홈',},
            {
              text: '공지사항',
            },
            {
              text: '공지사항',
              active: '상세'
            },
          ],
        },
      },
      {
        path: 'event',
        name: 'sonax-user-event',
        component: () => import('@/views/sonax-user/event/EventMain.vue'),
        meta: {          
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '이벤트',
          breadcrumb: [
            { text: '홈',},
            {
              text: '이벤트',
              active: true,
            },
          ],
        },
      },
      {
        path: 'event/detail',
        name: 'sonax-user-event-detail',
        component: () => import('@/views/sonax-user/event/EventDetail.vue'),
        props: (route) => ({ 
          eventId: Number.parseInt(route.query.eventId, 10),
          tab: route.query.tab,
          rn: Number.parseInt(route.query.rn, 10),
        }),
        meta: {          
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '이벤트',
          breadcrumb: [
            { text: '홈',},
            {
              text: '이벤트',
              active: true,
            },
            {
              text: '이벤트 상세',
              active: true,
            },
          ],
        },
      },
      {
        path: 'push',
        name: 'sonax-user-push',
        component: () => import('@/views/sonax-user/push/Push.vue'),
        meta: {
          requireLogin : true,  
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '알림',
          breadcrumb: [
            { text: '홈',},
            {
              text: '알림',
              active: true,
            },
          ],
        },
      },
      
      {
        path: 'coupon',
        name: 'sonax-user-coupon',
        component: () => import('@/views/sonax-user/coupon/Coupon.vue'),
        meta: {
          requireLogin : false,  
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '쿠폰&혜택',
          breadcrumb: [
            { text: '홈',},
            {
              text: '쿠폰&혜택',
              active: true,
            },
          ],
        },
      }
    ]
  },  
]

