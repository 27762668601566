//Layout
import BasicLayout from '@/layouts/BasicLayout';

export default [
  {
    path: '/sonax',
    component: BasicLayout,
    children: [
      {
        path: 'login',
        name: 'sonax-user-login',
        props: true,
        component: () => import('@/views/sonax-user/login/Login.vue'),
        meta: {
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '로그인',
          breadcrumb: [
            { text: '홈', },
            {
              text: '로그인',
              active: true,
            },
          ],
        },
      },
      {
        path: 'snsLogin',
        name: 'sonax-user-sns-login',
        props: true,
        component: () => import('@/views/sonax-user/login/SnsLogin.vue'),
        meta: {
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '로그인',
          breadcrumb: [
            { text: '홈', },
            {
              text: '로그인',
              active: true,
            },
          ],
        },
      },
      {
        path: 'dormantPage1',
        name: 'sonax-user-dormant-page1',
        props: true,
        component: () => import('@/views/sonax-user/login/DormantPage1.vue'),
        meta: {
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '로그인',
          breadcrumb: [
            { text: '홈', },
            {
              text: '로그인',
              active: true,
            },
          ],
        },
      },
      {
        path: 'dormantPage2',
        name: 'sonax-user-dormant-page2',
        props: true,
        component: () => import('@/views/sonax-user/login/DormantPage2.vue'),
        meta: {
          wrapClass : ['m-sub', 'm-nav-none'],
          pageTitle: '로그인',
          breadcrumb: [
            { text: '홈', },
            {
              text: '로그인',
              active: true,
            },
          ],
        },
      },
    ]
  },  
]

