import main from './main';

import login from './login';
import join from './join';
import service from './service';
import myPage from './myPage';
import cm from './customerService';
import preview from './preview';

import test from './test';

const data = [
  ...main,
  ...login,
  ...join,
  ...service,
  ...myPage,
  ...cm,
  ...preview,
  ...test
]

export default data
