import request from '@/utils/request'

const getPushList = async function(params){
    return request({
        url: '/api/user/noti/list.api',
        method: 'post',
        data: params,
    });
};

const setViews = async function(params){
    return request({
        url: '/api/user/noti/set.api',
        method: 'post',
        data: params,
    });
};

const setPushToken = async function(params){
    return request({
        url: '/api/user/noti/setPushToken.api',
        method: 'post',
        data: params,
    });
};

export {
    getPushList,
    setViews,

    setPushToken,
}