<template>
  <fragment>
    <AppHeader/>
    <AppMain />
    <AppFooter />    
  </fragment>
</template>

<script>
import { AppHeader, AppMain, AppFooter} from './components'
import { mapState } from 'vuex';
import ernsUtils from '@/components/mixins/ernsUtils';
import {getUserData} from '@/auth/utils';

export default {
  name: 'Layout',
  components: {
    AppHeader,
    AppMain,
    AppFooter ,  
  },
  mixins: [ernsUtils],  
  computed: {
    ...mapState({
      sidebar: state => state.app.sidebar,
      device: state => state.app.device,
      showSettings: state => state.settings.showSettings,
      needTagsView: state => state.settings.tagsView,
      fixedHeader: state => state.settings.fixedHeader
    }),
    classObj() {
      return {
        mobile: this.device === 'mobile'
      }
    }    
  },
  mounted(){
    const _vm = this;    
    _vm.docReady();
    
    _vm.loadLoginInfo();
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    },

    loadLoginInfo(){
      const _vm = this;
      const userInfo = getUserData();
      _vm.$store.dispatch('erns/setUserInfo', userInfo);      
    },

    docReady(){
      const _vm = this;
    },
  }
}
</script>

<style lang="scss" scoped>
  
</style>
<style lang="scss">

</style>