import request from '@/utils/request'

const getTermList = async function(params){
    return request({
        url: '/api/cmm/terms/list.api',
        method: 'post',
        data: params
    });
};

export {
    getTermList,
}